/* eslint-disable no-param-reassign */
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import React, { PropsWithChildren, memo, useMemo } from 'react';

import Sentry from '@advisor/utils/Sentry';
import Logger from '@advisor/utils/Logger';
import ifExists from '@advisor/utils/ifExists';
import { useEvent } from '@advisor/utils/hooks';
import ComplexError from '@advisor/utils/ComplexError';
import VideoRoomAPIContext from '../context';
import {
  VideoRoomAPI,
  VideoCallStatus,
  SessionMetadata,
  ScreenShareStatus,
} from '../types';
import callStateAtom from '../callStateAtom';
import useIsAPIProviderActive from '../useIsAPIProviderActive';
import Daily from './LibProvider';
import {
  activeParticipantIdAtom,
  localSessionAtom,
  sessionsAtom,
} from './sessions';
import { dailyStateAtom } from './dailyState';
import subscribeToCallEvent from './subscribeToCallEvent';
import useOnCustomEvent from './useOnCustomEvent';
import useEmitCustomEvent from './useEmitCustomEvent';
import usePrepareVideoRoomForRecording from './usePrepareVideoRoomForRecording';
import useOnSessionLeft from './useOnSessionLeft';
import useSpeakerState from './useSpeakerState';
import StaticDailyAPIContext from './staticDailyAPIContext';

function useCleanUp() {
  const [dailyState, setDailyState] = useAtom(dailyStateAtom);

  return useEvent(async () => {
    if (!dailyState) {
      return;
    }

    await dailyState.destroy();
    setDailyState(undefined);
  });
}

function useOnError() {
  const setCallState = useSetAtom(callStateAtom);

  return useEvent((cause: unknown) => {
    setCallState(
      ifExists((draft) => {
        draft.status = VideoCallStatus.Error;
      }),
    );
    Sentry.captureException(cause);
  });
}

const CallStatusEvents = [
  'joined-meeting',
  'left-meeting',
  'error',
  'camera-error',
] as const;

function useJoin() {
  const cleanUp = useCleanUp();
  const leave = useLeave();
  const onError = useOnError();
  const [dailyState, setDailyState] = useAtom(dailyStateAtom);
  const setCallState = useSetAtom(callStateAtom);

  return useEvent(
    async (
      url: string | null,
      token: string | null,
      metadata: SessionMetadata,
    ) => {
      if (dailyState) {
        // Destroying the old state
        await cleanUp();
      }

      if (!url || !token) {
        onError(
          new Error(
            `Tried to join Daily.co with either no url or no token. url=${url}, token=${token}`,
          ),
        );
        return;
      }

      const call = Daily.createCallObject({
        userData: JSON.stringify(metadata),
      });

      setDailyState({
        call,
        async destroy() {
          if (!call.isDestroyed()) {
            unsubscribeList.forEach((unsubscribe) => unsubscribe());
            await call.destroy();
          }
        },
      });

      async function handleNewMeetingState(event?: unknown) {
        switch (call.meetingState()) {
          case 'joined-meeting':
            Logger.log('[Daily] Joined meeting');
            setCallState(
              ifExists((draft) => {
                draft.status = VideoCallStatus.Joined;
              }),
            );
            break;
          case 'left-meeting':
            Logger.log('[Daily] Left meeting');
            await leave();
            break;
          case 'error':
            onError(
              new ComplexError(`Daily received 'error' meeting state`, event),
            );
            break;
          default:
            break;
        }
      }

      // Use initial state
      handleNewMeetingState();

      // Hooking up events
      const unsubscribeList = CallStatusEvents.map((eventName) =>
        subscribeToCallEvent(call, eventName, handleNewMeetingState),
      );

      try {
        // add a meeting token here if your room is private
        const joinDetails = {
          url,
          token,
        };

        await call.preAuth(joinDetails);
        await call.join(joinDetails);
      } catch (e) {
        onError(
          new ComplexError(`Failed to preAuth or join the Daily.co room`, e),
        );
      }
    },
  );
}

function useLeave() {
  const dailyState = useAtomValue(dailyStateAtom);
  const setCallState = useSetAtom(callStateAtom);
  const cleanUp = useCleanUp();

  return useEvent(async () => {
    setCallState(null);

    if (!dailyState) {
      // Already left and cleaned-up
      return;
    }

    Logger.log('[Daily] Leaving the meeting');

    try {
      await dailyState.call.leave();
    } catch (e) {
      // This can fail if the call ended due to an error already
    }

    await cleanUp();
  });
}

function useScreenShareStatus() {
  const localSession = useAtomValue(localSessionAtom);

  return useMemo(() => {
    if (!localSession) {
      return ScreenShareStatus.NotSharing;
    }

    const trackState = localSession.tracks.screenVideo.state;

    if (trackState === 'loading') {
      return ScreenShareStatus.Pending;
    }

    if (trackState === 'playable' || trackState === 'sendable') {
      return ScreenShareStatus.Sharing;
    }

    return ScreenShareStatus.NotSharing;
  }, [localSession]);
}

const DailyAPIProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isProviderActive = useIsAPIProviderActive('daily');

  const join = useJoin();
  const leave = useLeave();

  const dailyState = useAtomValue(dailyStateAtom);

  const toggleAudio = useEvent((enabled: boolean) => {
    dailyState?.call.setLocalAudio(enabled);
  });

  const toggleVideo = useEvent((enabled: boolean) => {
    dailyState?.call.setLocalVideo(enabled);
  });

  const startScreenShare = useEvent(() => {
    dailyState?.call.startScreenShare();
  });

  const stopScreenShare = useEvent(() => {
    dailyState?.call.stopScreenShare();
  });

  const cycleCamera = useEvent(async () => {
    try {
      await dailyState?.call.cycleCamera();
    } catch (e) {
      Sentry.captureException(e);
    }
  });

  const [speakerEnabled, toggleSpeaker] = useSpeakerState();

  const updateSessionMetadata = useEvent(async (value: SessionMetadata) => {
    await dailyState?.call.setUserData(JSON.stringify(value));
  });

  const emitCustomEvent = useEmitCustomEvent();
  const onCustomEvent = useOnCustomEvent();
  const onSessionLeft = useOnSessionLeft();
  const prepareVideoRoomForRecording = usePrepareVideoRoomForRecording();

  const screenShareStatus = useScreenShareStatus();
  const sessions = useAtomValue(sessionsAtom);
  const activeParticipantId = useAtomValue(activeParticipantIdAtom);

  const getSessions = useEvent(() => sessions);

  const context: VideoRoomAPI = useMemo(
    () => ({
      join,
      leave,

      toggleAudio,
      toggleVideo,
      toggleSpeaker,
      startScreenShare,
      stopScreenShare,
      cycleCamera,

      updateSessionMetadata,
      prepareVideoRoomForRecording,

      emitCustomEvent,
      onCustomEvent,
      onSessionLeft,

      getSessions,

      screenShareStatus,
      sessions,
      speakerEnabled,
      activeParticipantId,
    }),
    [
      join,
      leave,
      toggleAudio,
      toggleVideo,
      toggleSpeaker,
      startScreenShare,
      stopScreenShare,
      cycleCamera,
      updateSessionMetadata,
      prepareVideoRoomForRecording,
      emitCustomEvent,
      onCustomEvent,
      onSessionLeft,

      getSessions,

      screenShareStatus,
      sessions,
      speakerEnabled,
      activeParticipantId,
    ],
  );

  const content = (
    <StaticDailyAPIContext.Provider value={context}>
      {children}
    </StaticDailyAPIContext.Provider>
  );

  if (isProviderActive) {
    return (
      <VideoRoomAPIContext.Provider value={context}>
        {content}
      </VideoRoomAPIContext.Provider>
    );
  }

  return content;
};

export default memo(DailyAPIProvider);
