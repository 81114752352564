/* eslint-disable no-param-reassign */
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { MessageEvents } from '@jellyfish-dev/ts-client-sdk';
import React, { PropsWithChildren, memo, useEffect, useMemo } from 'react';

import Sentry from '@advisor/utils/Sentry';
import ifExists from '@advisor/utils/ifExists';
import { useEvent } from '@advisor/utils/hooks';
import { warnExhaustive } from '@advisor/utils/typeAssertions';
import ComplexError from '@advisor/utils/ComplexError';
import VideoRoomAPIContext from '../context';
import {
  VideoRoomAPI,
  Sessions,
  SessionMetadata,
  EmitCustomEventResult,
  PrepareVideoRoomForRecordingResult,
  VideoCallStatus,
  CallSession,
  ScreenShareStatus,
} from '../types';
import callStateAtom from '../callStateAtom';
import useIsAPIProviderActive from '../useIsAPIProviderActive';
import StaticMembraneAPIContext from './staticMembraneAPIContext';
import { localSessionAtom } from './web/localSession';
import jellyfishClient, { TrackMetadata } from './web/jellyfishClient';
import {
  toggleScreenShareAtom,
  toggleCameraAtom,
  toggleMicrophoneAtom,
  screenShareAtom,
  disableAllPeripheralsAtom,
} from './web/peripherals';
import { remoteSessionsAtom } from './web/remoteSessions';

function useMembraneEvent<
  E extends keyof MessageEvents<SessionMetadata, TrackMetadata>,
>(
  event: E,
  listener: Required<MessageEvents<SessionMetadata, TrackMetadata>>[E],
) {
  const stableListener = useEvent(listener);

  useEffect(() => {
    jellyfishClient.on(event, stableListener);

    return () => {
      jellyfishClient.off(event, stableListener);
    };
  }, [event, stableListener]);
}

function useJoin() {
  const setLocalSession = useSetAtom(localSessionAtom);

  return useEvent((url: string, token: string, metadata: SessionMetadata) => {
    jellyfishClient.connect({
      peerMetadata: metadata,
      token,
      signaling: {
        protocol: 'wss',
        host: url,
      },
    });

    setLocalSession({
      isLocal: true,
      metadata,
      tracks: {
        audio: { track: null },
        video: { track: null },
        screenAudio: { track: null },
        screenVideo: { track: null },
      },
    });
  });
}

function useLeave() {
  const setCallState = useSetAtom(callStateAtom);
  const setLocalSession = useSetAtom(localSessionAtom);
  const disableAllPeripherals = useSetAtom(disableAllPeripheralsAtom);

  return useEvent(async () => {
    setCallState(null);
    setLocalSession(null);
    disableAllPeripherals();

    jellyfishClient.disconnect();
  });
}

function useReactToJoined() {
  const setCallState = useSetAtom(callStateAtom);
  const setLocalSession = useSetAtom(localSessionAtom);

  useMembraneEvent('joined', async (peerId, _peersInRoom) => {
    setCallState(
      ifExists((draft) => {
        draft.status = VideoCallStatus.Joined;
      }),
    );
    setLocalSession(
      ifExists((draft) => {
        draft.sessionId = peerId;
      }),
    );
  });
}

function useScreenShareStatus() {
  const status = useAtomValue(screenShareAtom);

  return useMemo(() => {
    if (status === 'enabled') {
      return ScreenShareStatus.Sharing;
    }

    if (status === 'enabling') {
      return ScreenShareStatus.Pending;
    }

    if (status === 'disabled') {
      return ScreenShareStatus.NotSharing;
    }

    warnExhaustive(status, 'MembraneAPIProvider/useScreenShareStatus');
    return ScreenShareStatus.NotSharing;
  }, [status]);
}

function useStartScreenShare() {
  const toggleScreenShare = useSetAtom(toggleScreenShareAtom);

  return useEvent(() => {
    toggleScreenShare(true);
  });
}

function useStopScreenShare() {
  const toggleScreenShare = useSetAtom(toggleScreenShareAtom);

  return useEvent(() => {
    toggleScreenShare(false);
  });
}

const MembraneAPIProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const remoteSessions = useAtomValue(remoteSessionsAtom);
  const [localSession, setLocalSession] = useAtom(localSessionAtom);
  const setCallState = useSetAtom(callStateAtom);

  const isProviderActive = useIsAPIProviderActive('membrane');

  const onError = useEvent((cause: unknown) => {
    setCallState(
      ifExists((draft) => {
        draft.status = VideoCallStatus.Error;
      }),
    );
    Sentry.captureException(cause);
  });

  const join = useJoin();
  const leave = useLeave();

  const toggleVideo = useSetAtom(toggleCameraAtom);
  const toggleAudio = useSetAtom(toggleMicrophoneAtom);

  const toggleSpeaker = useEvent((_override?: boolean) => {
    // TODO:
  });

  const startScreenShare = useStartScreenShare();
  const stopScreenShare = useStopScreenShare();

  const cycleCamera = useEvent(async () => {});

  const updateSessionMetadata = useEvent(async (data: SessionMetadata) => {
    setLocalSession(
      ifExists((draft) => {
        draft.metadata = data;
      }),
    );
    jellyfishClient.updatePeerMetadata(data);
  });

  const emitCustomEvent = useEvent(() => {
    // TODO:
    return EmitCustomEventResult.NotConnected;
  });

  const onCustomEvent = useEvent(() => {
    // TODO:
    return null;
  });

  const prepareVideoRoomForRecording = useEvent(async () => {
    // TODO:
    return PrepareVideoRoomForRecordingResult.NotReady;
  });

  const onSessionLeft = useEvent(() => {
    // TODO:
    return null;
  });

  const screenShareStatus = useScreenShareStatus();

  const sessions: Sessions = useMemo(() => {
    if (!localSession?.sessionId) {
      return {};
    }

    return {
      ...remoteSessions,
      [localSession.sessionId]: localSession as CallSession,
    };
  }, [localSession, remoteSessions]);

  const getSessions = useEvent(() => sessions);

  useReactToJoined();

  useMembraneEvent('joinError', (metadata) => {
    onError(new ComplexError('Failed to join Membrane room', metadata));
  });

  const context: VideoRoomAPI = useMemo(
    () => ({
      join,
      leave,

      toggleAudio,
      toggleVideo,
      toggleSpeaker,
      startScreenShare,
      stopScreenShare,
      cycleCamera,

      updateSessionMetadata,
      prepareVideoRoomForRecording,

      emitCustomEvent,
      onCustomEvent,
      onSessionLeft,

      getSessions,

      activeParticipantId: undefined,
      speakerEnabled: false, // TODO:
      screenShareStatus,
      sessions,
    }),
    [
      join,
      leave,
      toggleAudio,
      toggleVideo,
      toggleSpeaker,
      startScreenShare,
      stopScreenShare,
      cycleCamera,
      updateSessionMetadata,
      prepareVideoRoomForRecording,
      emitCustomEvent,
      onCustomEvent,
      onSessionLeft,
      getSessions,

      screenShareStatus,
      sessions,
    ],
  );

  const content = (
    <StaticMembraneAPIContext.Provider value={context}>
      {children}
    </StaticMembraneAPIContext.Provider>
  );

  if (isProviderActive) {
    return (
      <VideoRoomAPIContext.Provider value={context}>
        {content}
      </VideoRoomAPIContext.Provider>
    );
  }

  return content;
};

export default memo(MembraneAPIProvider);
