import { relayStylePagination } from '@apollo/client/utilities';

import {
  StrictTypedTypePolicies,
  MilestoneInfoFragmentDoc,
  BulkUploadEntryInfoFragment,
  ChatRoomWithMembersFragmentDoc,
  ArchivalVideoRoomInfoFragmentDoc,
  MilestoneCategoryInfoFragmentDoc,
} from '../generated/graphql';
import { identifyChatRoomCache } from './customCacheKeys';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      greyLabeling: {
        keyArgs: false,
      },
      messages: relayStylePagination(['chatRoomId']),
      chatRooms: relayStylePagination(['status', 'typename', 'nameContains']),
      roleRequests: relayStylePagination(),
      joinRequests: {
        keyArgs: ['chatRoomId'],
        merge(_existing, incoming) {
          return incoming;
        },
      },
      privateNotes: relayStylePagination(['chatRoomId']),
      microbotMemories: relayStylePagination(['searchQuery']),
      videoChatHistory: relayStylePagination(['chatRoomId', 'count']),
      commentsForMilestone: relayStylePagination(['milestoneId']),
      chatRoom: {
        read(existing, { args, cache }) {
          if (!existing && args?.chatRoomId) {
            // This allows us to read chatroom if the query wasn't executed but chatroom is already cached.
            // For example chat rooms query fetches a list of chat rooms and all of them are stored in cache
            // so there is no need to execute chatroom query for any of them.

            const id = identifyChatRoomCache({ id: args.chatRoomId });
            const cached = cache.readFragment({
              fragment: ChatRoomWithMembersFragmentDoc,
              fragmentName: 'ChatRoomWithMembers',
              id,
            });

            if (cached) {
              return cached;
            }
          }
          return existing;
        },
      },
      advisors: relayStylePagination(),
      archivalVideoChat: {
        read(existing, { toReference, args, cache }) {
          // This allows us to retrieve archivalVideoChat from the cache if another query requested it.
          if (existing || !args?.chatRoomId || !args?.videoCount) {
            return existing;
          }

          const ref = toReference({
            __typename: 'ArchivalVideoChatRoom',
            chatRoomId: args.chatRoomId,
            count: args.videoCount,
          });

          if (!ref) {
            return undefined;
          }

          const id = cache.identify(ref);
          const cached = cache.readFragment({
            fragment: ArchivalVideoRoomInfoFragmentDoc,
            fragmentName: 'ArchivalVideoRoomInfo',
            id,
          });

          return cached ?? undefined;
        },
      },
      milestoneCategory: {
        read(existing, { toReference, args, cache }) {
          // This allows us to retrieve milestoneCategory from the cache if another query requested it.
          if (existing || !args?.milestoneCategoryId) {
            return existing;
          }

          const ref = toReference({
            __typename: 'MilestoneCategory',
            id: args.milestoneCategoryId,
          });

          if (!ref) {
            return undefined;
          }

          const id = cache.identify(ref);
          const cached = cache.readFragment({
            fragment: MilestoneCategoryInfoFragmentDoc,
            fragmentName: 'MilestoneCategoryInfo',
            id,
          });

          return cached ?? undefined;
        },
      },
      milestonesForCategory: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      milestone: {
        // This allows us to retrieve milestone from the cache if another query requested it.
        read(existing, { toReference, args, cache }) {
          if (existing || !args?.milestoneId) {
            return existing;
          }

          const ref = toReference({
            __typename: 'Milestone',
            id: args.milestoneId,
          });

          if (!ref) {
            return undefined;
          }

          const cached = cache.readFragment({
            fragment: MilestoneInfoFragmentDoc,
            fragmentName: 'MilestoneInfo',
            id: cache.identify(ref),
          });

          return cached ?? undefined;
        },
      },
    },
  },
  StudentChatRoom: {
    // @ts-ignore
    keyFields: identifyChatRoomCache,
    fields: {
      members: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  RequestorChatRoom: {
    // @ts-ignore
    keyFields: identifyChatRoomCache,
    fields: {
      members: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  MicrobotMessage: {
    keyFields: ['id'],
    fields: {
      actions: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      footerMessage: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  RoleRequest: {
    keyFields: ['id'],
  },
  // Do not create separate fragment cache for system message data
  // because of pagination being newest to oldest, apollo overwrites
  // will overwrite up-to-date fragments with older instances from
  // previous (chronologically) system messages.
  DefaultSystemMessageData: {
    keyFields: false,
  },
  InitialVideoChatSystemMessageData: {
    keyFields: false,
  },
  VideoChatSystemMessageData: {
    keyFields: false,
  },
  JourneyCategorySystemMessageData: {
    keyFields: false,
  },
  JourneyMilestoneSystemMessageData: {
    keyFields: false,
  },
  JourneyFirstInteractionSystemMessageData: {
    keyFields: false,
  },
  JourneyMilestoneCommentSystemMessageData: {
    keyFields: false,
  },
  JourneyMilestoneOverDueSystemMessageData: {
    keyFields: false,
  },
  LatestMessageInfo: {
    keyFields: false,
  },
  VideoChatRoom: {
    keyFields: ['chatRoomId'],
    fields: {
      recordings: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      participants: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  ArchivalVideoChatRoom: {
    keyFields: ['chatRoomId', 'count'],
    fields: {
      recordings: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
      participants: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  StudentJourney: {
    keyFields: ['chatRoomId'],
    fields: {
      milestoneCategories: {
        merge(_existing, incoming) {
          return incoming;
        },
      },
    },
  },
  JourneyProfile: {
    keyFields: ['chatRoomId'],
  },
  MilestoneCategory: {
    keyFields: ['id'],
  },
  Milestone: {
    keyFields: ['id'],
  },
  User: {
    keyFields: ['id'],
  },
  FamilyJoinRequest: {
    keyFields: ['id'],
  },
  MicrobotBulkUpload: {
    keyFields: ['id'],
    fields: {
      entries: {
        merge(
          existing: BulkUploadEntryInfoFragment[] | undefined,
          incoming: BulkUploadEntryInfoFragment[] | undefined,
        ) {
          const entries = [...(existing ?? [])];

          incoming?.forEach((entry) => {
            const index = entries.findIndex((e) => e.row === entry.row);

            if (index !== -1) {
              entries[index] = entry;
            } else {
              entries.push(entry);
            }
          });

          // MicrobotBulkUpload that are comming through subscription contains entries
          // that are related only with the entries batch, that the event was generated for.
          // Thus in cache with have to merge them with already cached entries, not to lose
          // data about already processed entries.
          return entries;
        },
      },
    },
  },
};

export default typePolicies;
