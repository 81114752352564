import React, { memo } from 'react';
import { useMolecule } from 'bunshi/react';
import { useTranslation } from 'react-i18next';
import { useAtomValue, useSetAtom } from 'jotai';

import { Scope } from '@advisor/api/scope';
import { useEvent } from '@advisor/utils/hooks';
import { ContextMenu } from '@advisor/design/components';
import { MessageInputMolecule } from '../hooks/MessageInput';
import { MessageMolecule, useUserMessage } from '../messaging/messageMolecule';
import copyMessageToClipboard from './copyMessageToClipboard';
import useToggleTranslation from './useToggleTranslation';
import SelectedMessageMolecule from './SelectedMessageMolecule';
import useDeleteMessage from './useDeleteMessage';
import { MessageMenuProps } from './types';

function useMenuAction(fn: () => unknown): () => void {
  const { selectedMessageAtom } = useMolecule(SelectedMessageMolecule);
  const setSelectedMessage = useSetAtom(selectedMessageAtom);

  return useEvent(() => {
    // Clearing what message we were targeting with our dropdown actions.
    setSelectedMessage(null);
    fn();
  });
}

const MessageMenu: React.FC<MessageMenuProps> = (props) => {
  const { onOpenInfo } = props;

  const { t } = useTranslation('common');
  const { isOwnAtom } = useMolecule(MessageMolecule);
  const { editMessageAtom } = useMolecule(MessageInputMolecule);

  const message = useUserMessage('required');
  const isOwnMessage = useAtomValue(isOwnAtom);

  const isMicrobotConversation = useAtomValue(
    Scope.microbotConversation(message.chatRoomId),
  );

  const editMessage = useSetAtom(editMessageAtom);
  const deleteMessage = useDeleteMessage(message);
  const translation = useToggleTranslation();

  const onEditMessage = useMenuAction(() => {
    editMessage(message);
  });

  const onCopyMessage = useMenuAction(() => {
    copyMessageToClipboard(message);
  });

  const onDeleteMessage = useMenuAction(deleteMessage);

  const onToggleTranslation = useMenuAction(() => {
    translation.toggle();
  });

  const myMenu = (
    <>
      {!isMicrobotConversation && (
        <ContextMenu.Item
          iconName="Edit"
          onPress={onEditMessage}
          label={t('edit-message')}
          color="primary"
          testID="editMessageButton"
        />
      )}
      <ContextMenu.Item
        iconName="Copy"
        onPress={onCopyMessage}
        label={t('copy-text')}
        color="primary"
        testID="copyMessageButton"
      />
      {!isMicrobotConversation && (
        <ContextMenu.Item
          iconName="InfoCircle"
          onPress={onOpenInfo}
          label={t('message-info')}
          color="primary"
          testID="infoMessageButton"
        />
      )}
      <ContextMenu.Item
        destructive
        iconName="Delete"
        onPress={onDeleteMessage}
        label={t('delete-message')}
        color="secondary"
        testID="deleteMessageButton"
      />
    </>
  );

  const othersMenu = (
    <>
      <ContextMenu.Item
        label={
          translation.status?.type === 'translated' ||
          translation.status?.type === 'in_progress'
            ? t('restore-translation')
            : t('translate')
        }
        iconName="Translate"
        color="primary"
        onPress={onToggleTranslation}
        disabled={translation.isDisabled}
      />
      <ContextMenu.Item
        iconName="Copy"
        onPress={onCopyMessage}
        label={t('copy-text')}
        color="primary"
      />
      {!isMicrobotConversation && (
        <ContextMenu.Item
          iconName="InfoCircle"
          onPress={onOpenInfo}
          label={t('message-info')}
          color="primary"
        />
      )}
    </>
  );

  return (
    <ContextMenu.Root triggerTestID="messageDropDownButton">
      {isOwnMessage ? myMenu : othersMenu}
    </ContextMenu.Root>
  );
};

export default memo(MessageMenu);
